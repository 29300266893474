import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import TitleSubtitle from '../components/title-subtitle'
import TitleSubtitleImg from '../components/title-subtitle-img'
import Objectives from '../components/objectives'
import Team from '../components/team'
import Footer from '../components/footer'
import './about-us.css'

const AboutUs = (props) => {
  return (
    <div className="about-us-container">
      <Helmet>
        <title>About-Us - Sapna IOT</title>
        <meta
          name="description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
        <meta property="og:title" content="About-Us - Sapna IOT " />
        <meta
          property="og:description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
      </Helmet>
      <Header
        link1={
          <Fragment>
            <span className="about-us-text10 thq-body-small thq-link">
              Home
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="about-us-text11 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="about-us-text12 thq-body-small thq-link">
              Services
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="about-us-text13 thq-body-small thq-link">
              Tracking
            </span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="about-us-text14 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="about-us-text15 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="about-us-text16">Login</span>
          </Fragment>
        }
        link1Url="/"
        link2Url="/about-us"
        link3Url="/error"
        link4Url="https://www.iopgps.com/"
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name1"
      ></Header>
      <TitleSubtitle
        content1={
          <Fragment>
            <span className="about-us-text17 thq-body-large">
              To create an environment conducive to continual community
              vitality.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-us-text18 thq-heading-1">Our Mission</span>
          </Fragment>
        }
        rootClassName="title-subtitleroot-class-name1"
      ></TitleSubtitle>
      <TitleSubtitleImg
        content1={
          <Fragment>
            <span className="about-us-text19 thq-body-large">
              To design close gaps in existing community development programs by
              using well-established principles from research on effective
              community development processes
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-us-text20 thq-heading-1"> Our Mission</span>
          </Fragment>
        }
      ></TitleSubtitleImg>
      <Objectives
        text={
          <Fragment>
            <span className="about-us-text21 thq-body-small">
              We providing training in different technologies and We make you
              zero to hero, and get a certificate
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="about-us-text22 thq-body-small">
              {' '}
              To involve citizens examining their present community problems and
              choosing to renew it with simple solutions.(Consulting)
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="about-us-text23 thq-body-small">
              To achieve diversity and develop new community leaders in
              technology ( Training)
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="about-us-text24 thq-body-small">
              For developing new research directions in educational and
              employment skill levels of several communities
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <span className="about-us-text25 thq-heading-2">Objectives</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-us-text26 thq-heading-2">1</span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="about-us-text27 thq-heading-2">2</span>
          </Fragment>
        }
        heading3={
          <Fragment>
            <span className="about-us-text28 thq-heading-2">3</span>
          </Fragment>
        }
      ></Objectives>
      <Team
        member1={
          <Fragment>
            <span className="about-us-text29 thq-body-small">
              Dr. D Narendar Singh
            </span>
          </Fragment>
        }
        member2={
          <Fragment>
            <span className="about-us-text30 thq-body-small">
              B. Pavitra (Ph.D)
            </span>
          </Fragment>
        }
        member3={
          <Fragment>
            <span className="about-us-text31 thq-body-small">
              R. Naga Swetha (Ph.D)
            </span>
          </Fragment>
        }
        member4={
          <Fragment>
            <span className="about-us-text32 thq-body-small">
              G. Anil Kumar (Ph.D)
            </span>
          </Fragment>
        }
        member5={
          <Fragment>
            <span className="about-us-text33 thq-body-small">
              Ashwini Rao Tuniki (Ph.D)
            </span>
          </Fragment>
        }
        member6={
          <Fragment>
            <span className="about-us-text34 thq-body-small">G. Sai Rohit</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="about-us-text35 thq-body-small">
              Join our team and make a difference in your career!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-us-text36 thq-heading-2">Meet Our Team</span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="about-us-text37 thq-heading-2">We’re hiring!</span>
          </Fragment>
        }
        member1Job={
          <Fragment>
            <span className="about-us-text38 thq-body-small">Founder</span>
          </Fragment>
        }
        member2Job={
          <Fragment>
            <span className="about-us-text39 thq-body-small">CEO</span>
          </Fragment>
        }
        member3Job={
          <Fragment>
            <span className="about-us-text40 thq-body-small">
              Managing Director
            </span>
          </Fragment>
        }
        member3Src="/assets/r.naga-swetha-1400w.jpg"
        member4Job={
          <Fragment>
            <span className="about-us-text41 thq-body-small">
              Production Engineer
            </span>
          </Fragment>
        }
        member4Src="/assets/g.anil-kumar-1400w.jpg"
        member5Job={
          <Fragment>
            <span className="about-us-text42 thq-body-small">
              Project Manager
            </span>
          </Fragment>
        }
        member5Src="/assets/g.ashwini-1400w.jpg"
        member6Job={
          <Fragment>
            <span className="about-us-text43 thq-body-small">Web Dev</span>
          </Fragment>
        }
        member6Src="/assets/rohit-1400w.jpeg"
        actionContent={
          <Fragment>
            <span className="about-us-text44 thq-body-small">
              Open positions
            </span>
          </Fragment>
        }
        member1Content={
          <Fragment>
            <span className="about-us-text45 thq-body-small">
              Narendar is a visionary leader with a passion for IoT technology,
              ML &amp; AI.
            </span>
          </Fragment>
        }
        member2Content={
          <Fragment>
            <span className="about-us-text46 thq-body-small">
              Pavitra is an expert in developing cutting-edge IoT solutions.
            </span>
          </Fragment>
        }
        member3Content={
          <Fragment>
            <span className="about-us-text47 thq-body-small">
              Naga Swetha brings extensive experience in IOT, Machine learning
              to our team.
            </span>
          </Fragment>
        }
        member4Content={
          <Fragment>
            <span className="about-us-text48 thq-body-small">
              Anil Kumar excels in Image processing, ML &amp; communication
              systems
            </span>
          </Fragment>
        }
        member5Content={
          <Fragment>
            <span className="about-us-text49 thq-body-small">
              Ashwini Rao has a proven track record in IOT , Deep Learning.
            </span>
          </Fragment>
        }
        member6Content={
          <Fragment>
            <span className="about-us-text50 thq-body-small">
              Rohit is responsible for managing the website.
            </span>
          </Fragment>
        }
      ></Team>
      <Footer
        link1={
          <Fragment>
            <span className="about-us-text51 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="about-us-text52 thq-body-small">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="about-us-text53 thq-body-small">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="about-us-text54 thq-body-small">Flowmeter</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="about-us-text55 thq-body-small">Tracking</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="about-us-text56 thq-body-small">
              © 2024 Sapna IOT Solutions
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="about-us-text57 thq-body-small">
              Terms of Service
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="about-us-text58 thq-body-small">
              Cookies Settings
            </span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="about-us-text59 thq-body-small">
              Privacy Policy
            </span>
          </Fragment>
        }
        rootClassName="footerroot-class-name6"
      ></Footer>
    </div>
  )
}

export default AboutUs
