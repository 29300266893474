import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './title-subtitle.css'

const TitleSubtitle = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="title-subtitle-max-width thq-section-max-width">
        <div className="title-subtitle-content">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="title-subtitle-text3 thq-heading-1">
                  Default value
                </h1>
              </Fragment>
            )}
          </h1>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="title-subtitle-text4 thq-body-large">
                  Default value
                </p>
              </Fragment>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

TitleSubtitle.defaultProps = {
  heading1: undefined,
  rootClassName: '',
  content1: undefined,
}

TitleSubtitle.propTypes = {
  heading1: PropTypes.element,
  rootClassName: PropTypes.string,
  content1: PropTypes.element,
}

export default TitleSubtitle
