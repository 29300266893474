import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Title from '../components/title';
import Footer from '../components/footer';
import './profile.css';

const Profile = () => {
  const deviceId = 'R123'; // Your device ID
  const topic = `${deviceId}/dts`;
  const [gpsData, setGpsData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [client, setClient] = useState(null);

  useEffect(() => {
    // Load the MQTT script
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/mqtt/dist/mqtt.min.js';
    script.async = true;

    script.onload = () => {
      const mqttClient = window.mqtt.connect('wss://test.mosquitto.org:8081');

      mqttClient.on('connect', () => {
        console.log('Connected to MQTT broker');
        mqttClient.subscribe(topic);
        setClient(mqttClient); // Save the client for later use in publishing
      });

      mqttClient.on('message', (topic, message) => {
        const parsedMessage = JSON.parse(message.toString());

        // Handle GPS data
        if (parsedMessage.lat && parsedMessage.lon) {
          setGpsData({
            lat: parsedMessage.lat,
            lon: parsedMessage.lon,
            timestamp: new Date(parsedMessage.ts).toLocaleString(),
          });
        }

        // Handle Image data
        if (parsedMessage.img && !parsedMessage.mode) {
          setImageData(`data:image/jpeg;base64,${parsedMessage.img}`);
        }

        // Handle Device status and image if present
        if (parsedMessage.mode) {
          setDeviceStatus({
            lat: parsedMessage.lat,
            lon: parsedMessage.lon,
            timestamp: new Date(parsedMessage.ts).toLocaleString(),
            mode: parsedMessage.mode,
            auth: parsedMessage.auth,
          });

          if (parsedMessage.img) {
            setImageData(`data:image/jpeg;base64,${parsedMessage.img}`);
          }
        }
      });
    };

    document.body.appendChild(script);

    return () => {
      if (client) {
        client.end();
      }
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [deviceId, topic, client]);

  const publishMessage = (message) => {
    if (client) {
      client.publish(topic, JSON.stringify(message));
      console.log('Published message:', message);
    } else {
      console.log('Client not connected');
    }
  };

  const requestImage = () => {
    const imageRequest = { id: deviceId, img: 'img' };
    publishMessage(imageRequest);
  };

  return (
    <div className="profile-container1">
      <Helmet>
        <title>Profile - Sapna IOT</title>
        <meta name="description" content="Future Forward: Pioneering the next wave of startup innovation." />
        <meta property="og:title" content="Profile - Sapna IOT " />
        <meta property="og:description" content="Future Forward: Pioneering the next wave of startup innovation." />
      </Helmet>
      <Header
        link1={<span className="profile-text10 thq-body-small thq-link">Home</span>}
        link2={<span className="profile-text11 thq-body-small thq-link">About Us</span>}
        link3={<span className="profile-text12 thq-body-small thq-link">Services</span>}
        link4={<span className="profile-text13 thq-body-small thq-link">Tracking</span>}
        link5={<span className="profile-text14 thq-body-small thq-link">Flowmeter</span>}
        action1={<span className="profile-text16">Login</span>}
        link1Url="/"
        link2Url="/about-us"
        link3Url="/services"
        link4Url="https://www.iopgps.com/"
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name9"
      />
      <Title heading1={<span className="profile-text17 thq-heading-1">Welcome, User</span>} rootClassName="titleroot-class-name5" />
      <div className="profile-container2">
        <div className="profile-container3">
          <div className="container mt-5">
            <h1 className="text-center mb-4">Dashboard</h1>
            <div className="row justify-content-center">
              {/* GPS Data Card */}
              <div className="col-12 col-lg-3 mb-3">
                <div className="card text-center">
                  <div className="card-body">
                    <h5 className="card-title">GPS Data</h5>
                    {gpsData ? (
                      <div>
                        <p>Latitude: {gpsData.lat}</p>
                        <p>Longitude: {gpsData.lon}</p>
                        <p>Timestamp: {gpsData.timestamp}</p>
                      </div>
                    ) : (
                      <p className="card-text">Waiting for GPS data...</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Device Status Card */}
              <div className="col-12 col-lg-3 mb-3">
                <div className="card text-center">
                  <div className="card-body">
                    <h5 className="card-title">Device Status</h5>
                    {deviceStatus ? (
                      <div>
                        <p>Latitude: {deviceStatus.lat}</p>
                        <p>Longitude: {deviceStatus.lon}</p>
                        <p>Timestamp: {deviceStatus.timestamp}</p>
                        <p>Mode: {deviceStatus.mode}</p>
                        <p>Auth Status: {deviceStatus.auth}</p>
                      </div>
                    ) : (
                      <p className="card-text">Waiting for Device Status...</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Image Preview Card */}
              <div className="col-12 col-lg-3 mb-3">
                <div className="card text-center">
                  <div className="card-body">
                    <h5 className="card-title">Image Preview</h5>
                    {imageData ? (
                      <img
                        src={imageData}
                        alt="Received"
                        className="img-fluid"
                        style={{ borderRadius: '8px', marginBottom: '15px' }}
                      />
                    ) : (
                      <p className="card-text">Waiting for Image data...</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Button to Request Image */}
              <div className="col-12 col-lg-3 mb-3">
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={requestImage}
                  >
                    Request Image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        link1={<span className="profile-text18 thq-body-small">Home</span>}
        link2={<span className="profile-text19 thq-body-small">About Us</span>}
        link3={<span className="profile-text20 thq-body-small">Services</span>}
        link4={<span className="profile-text21 thq-body-small">Flowmeter</span>}
        link5={<span className="profile-text22 thq-body-small">Tracking</span>}
        content3={<span className="profile-text23 thq-body-small">© 2024 Sapna IOT Solutions</span>}
        termsLink={<span className="profile-text24 thq-body-small">Terms of Service</span>}
        cookiesLink={<span className="profile-text25 thq-body-small">Cookies Settings</span>}
        privacyLink={<span className="profile-text26 thq-body-small">Privacy Policy</span>}
        rootClassName="footerroot-class-name8"
      />
    </div>
  );
};

export default Profile;
