import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import TitleSubtitle from '../components/title-subtitle'
import Feature from '../components/feature'
import Contact from '../components/contact'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Sapna IOT</title>
        <meta
          name="description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
        <meta property="og:title" content="Sapna IOT " />
        <meta
          property="og:description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
      </Helmet>
      <Header
        link1={
          <Fragment>
            <span className="home-text10 thq-body-small thq-link">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text11 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text12 thq-body-small thq-link">
              Services
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text13 thq-body-small thq-link">
              Tracking
            </span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text14 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="home-text15 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text16">Login</span>
          </Fragment>
        }
        link1Url="/"
        link2Url="/about-us"
        link3Url="/services"
        link4Url="https://www.iopgps.com/"
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name"
      ></Header>
      <TitleSubtitle
        content1={
          <Fragment>
            <span className="home-text17 thq-body-large">
              The advanced Start-up Company paving the way to the future. We are
              a team of like-minded and determined individuals, all sharing a
              vision for success. We believe that our sophisticated technology
              has the potential to become an industry sensation. Would you like
              to find out more? Explore our website today
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text18 thq-heading-1">
              Welcome to SAPNA IOT Solutions
            </span>
          </Fragment>
        }
      ></TitleSubtitle>
      <TitleSubtitle
        content1={
          <Fragment>
            <span className="home-text19 thq-body-large">
              At Sapna IOT Solutions, we believe that our solutions will soon
              become one of the biggest segments in the industry. We’ve only
              just started, but we already know that every product we build
              requires hard-earned skills, dedication and a daring attitude.
              Continue reading and learn all there is to know about the smart
              tech behind our successful Start-up Company
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text20 thq-heading-1">What we do</span>
          </Fragment>
        }
        rootClassName="title-subtitleroot-class-name"
      ></TitleSubtitle>
      <Feature
        sectionTitle={
          <Fragment>
            <span className="home-text21 thq-heading-2">
              Training At Sapna
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text22 thq-heading-3">IOT training</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text23 thq-heading-3">AI &amp; ML</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text24 thq-heading-3">Marketing</span>
          </Fragment>
        }
        feature1ImageSrc="/assets/iot-600w.png"
        feature2ImageSrc="/assets/ai-ml-600w.png"
        feature3ImageSrc="/assets/trends-in-digital-marketing-600w.png"
        feature1Description={
          <Fragment>
            <span className="home-text25 thq-body-small">
              IoT has become a revolutionary concept that has already connected
              billions of things and it is getting bigger and better.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text26 thq-body-small">
              AI is the broader concept of enabling a machine or system to
              sense, reason, act, or adapt like a human, ML is an application of
              AI that allows machines to extract knowledge from data and learn
              from it autonomously
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text27 thq-body-small">
              Digital marketing, also called online marketing, is the promotion
              of brands to connect with potential customers using the internet
              and other forms of digital communication.
            </span>
          </Fragment>
        }
      ></Feature>
      <Feature
        sectionTitle={
          <Fragment>
            <span className="home-text28 thq-heading-2">Our Products</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text29 thq-heading-3">
              TrazKer | Track my Bus
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text30 thq-heading-3">
              Water Level Indicator
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text31 thq-heading-3">
              Hand Sanitizer Dispenser
            </span>
          </Fragment>
        }
        rootClassName="featureroot-class-name"
        feature1ImageSrc="/assets/1-600w.jpg"
        feature2ImageSrc="/assets/water-600w.jpg"
        feature3ImageSrc="/assets/shopping-600w.webp"
        feature1Description={
          <Fragment>
            <span className="home-text32 thq-body-small">
              Collage and school bus tracking system location-based service that
              helps track the location of school or college student while in
              transit, anytime, anywhere, thereby ensuring their safety. Parents
              are also notified about their children&apos;s whereabouts while in
              transit, ensuring them of the same
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text33 thq-body-small">
              <span>
                The purpose of a water level indicator is to gauge and manage
                water levels in a water tank ,
              </span>
              <span>
                The purpose of a water level indicator is to gauge and manage
                water levels in a water tank. This water level indicator comes
                with 3 sensor. Put all 3 sensors inside tank as shown in
                diagram. As shown in diagram G is common sensor. when water
                touch to &quot;1&quot; sensor, Level 1 LED glow. When water
                touch to &quot;2&quot; sensor, Level 2 LED glow. and so on... up
                to 3 level.
              </span>
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text36 thq-body-small">
              The touchless hand disinfection machine is designed to nebulize
              hand sanitizer, gel or alcohol, and provide automatic dosage of
              drop, which enables a quick and easy hand disinfection and
              eliminates cross contamination, achieving optimal hand hygiene.
            </span>
          </Fragment>
        }
      ></Feature>
      <Contact
        email1={
          <Fragment>
            <span className="home-text37 thq-body-small">
              asapnaiot@gmail.com
            </span>
          </Fragment>
        }
        phone1={
          <Fragment>
            <span className="home-text38 thq-body-small">+91- 9649126526</span>
          </Fragment>
        }
        address1={
          <Fragment>
            <span className="home-text39 thq-body-small">
              Central Facilities For Research &amp; Development, Osmania
              University Main Rd, Osmania University, Amberpet, Hyderabad,
              Telangana 500007
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text40 thq-body-large">
              Have a question or need assistance?
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text41 thq-body-large">
              Contact us for more information:
            </span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="home-text42 thq-body-large">
              We are here to help!.
            </span>
          </Fragment>
        }
        rootClassName="contactroot-class-name"
      ></Contact>
      <Footer
        link1={
          <Fragment>
            <span className="home-text43 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text44 thq-body-small">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text45 thq-body-small">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text46 thq-body-small">Flowmeter</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text47 thq-body-small">Tracking</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="home-text48 thq-body-small">
              © 2024 Sapna IOT Solutions
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text49 thq-body-small">Terms of Service</span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text50 thq-body-small">Cookies Settings</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text51 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name"
      ></Footer>
    </div>
  )
}

export default Home
