import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './login.css'

const Login = (props) => {
  return (
    <div className="login-container1">
      <Helmet>
        <title>Login - Sapna IOT</title>
        <meta
          name="description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
        <meta property="og:title" content="Login - Sapna IOT " />
        <meta
          property="og:description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
      </Helmet>
      <Header
        link1={
          <Fragment>
            <span className="login-text10 thq-body-small thq-link">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="login-text11 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="login-text12 thq-body-small thq-link">
              Services
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="login-text13 thq-body-small thq-link">
              Tracking
            </span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="login-text14 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="login-text15 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="login-text16">Login</span>
          </Fragment>
        }
        link1Url="/"
        link2Url="/about-us"
        link3Url="/services"
        link4Url="https://www.iopgps.com/ "
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name6"
      ></Header>
      <div className="login-container2 thq-section-padding">
        <div className="login-max-width thq-section-max-width">
          <div className="login-container3 thq-section-padding">
            <div className="login-form1">
              <h2 className="thq-heading-2 login-text17">Sign in</h2>
              <form className="login-form2">
                <div className="login-email">
                  <label
                    htmlFor="thq-sign-in-7-email"
                    className="thq-body-large"
                  >
                    ID
                  </label>
                  <input
                    type="email"
                    id="thq-sign-in-7-email"
                    required="true"
                    placeholder="Email address"
                    className="login-textinput1 thq-input thq-body-large"
                  />
                </div>
                <div className="login-password">
                  <div className="login-textfield">
                    <div className="login-container4">
                      <label
                        htmlFor="thq-sign-in-7-password"
                        className="thq-body-large"
                      >
                        Password
                      </label>
                      <div className="login-hide-password">
                        <svg viewBox="0 0 1024 1024" className="login-icon1">
                          <path d="M317.143 762.857l44.571-80.571c-66.286-48-105.714-125.143-105.714-206.857 0-45.143 12-89.714 34.857-128.571-89.143 45.714-163.429 117.714-217.714 201.714 59.429 92 143.429 169.143 244 214.286zM539.429 329.143c0-14.857-12.571-27.429-27.429-27.429-95.429 0-173.714 78.286-173.714 173.714 0 14.857 12.571 27.429 27.429 27.429s27.429-12.571 27.429-27.429c0-65.714 53.714-118.857 118.857-118.857 14.857 0 27.429-12.571 27.429-27.429zM746.857 220c0 1.143 0 4-0.571 5.143-120.571 215.429-240 432-360.571 647.429l-28 50.857c-3.429 5.714-9.714 9.143-16 9.143-10.286 0-64.571-33.143-76.571-40-5.714-3.429-9.143-9.143-9.143-16 0-9.143 19.429-40 25.143-49.714-110.857-50.286-204-136-269.714-238.857-7.429-11.429-11.429-25.143-11.429-39.429 0-13.714 4-28 11.429-39.429 113.143-173.714 289.714-289.714 500.571-289.714 34.286 0 69.143 3.429 102.857 9.714l30.857-55.429c3.429-5.714 9.143-9.143 16-9.143 10.286 0 64 33.143 76 40 5.714 3.429 9.143 9.143 9.143 15.429zM768 475.429c0 106.286-65.714 201.143-164.571 238.857l160-286.857c2.857 16 4.571 32 4.571 48zM1024 548.571c0 14.857-4 26.857-11.429 39.429-17.714 29.143-40 57.143-62.286 82.857-112 128.571-266.286 206.857-438.286 206.857l42.286-75.429c166.286-14.286 307.429-115.429 396.571-253.714-42.286-65.714-96.571-123.429-161.143-168l36-64c70.857 47.429 142.286 118.857 186.857 192.571 7.429 12.571 11.429 24.571 11.429 39.429z"></path>
                        </svg>
                        <span className="thq-body-small">Hide</span>
                      </div>
                    </div>
                    <input
                      type="password"
                      id="thq-sign-in-7-password"
                      required="true"
                      placeholder="Password"
                      className="login-textinput2 thq-input thq-body-large"
                    />
                  </div>
                  <a
                    href="https://teleporthq.io/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="login-link thq-body-small"
                  >
                    Forgot password
                  </a>
                </div>
              </form>
              <button type="submit" className="login-button1 thq-button-filled">
                <span className="login-text21 thq-body-small">Sign in</span>
              </button>
              <div className="login-divider1">
                <div className="login-divider2"></div>
                <p className="thq-body-large">OR</p>
                <div className="login-divider3"></div>
              </div>
              <button className="login-button2 thq-button-outline">
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="login-icon3"
                >
                  <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                </svg>
                <span className="thq-body-small">Continue with Facebook</span>
              </button>
            </div>
          </div>
        </div>
        
        <div className="login-container5"></div>
      </div>
      <Footer
        link1={
          <Fragment>
            <span className="login-text24 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="login-text25 thq-body-small">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="login-text26 thq-body-small">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="login-text27 thq-body-small">Flowmeter</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="login-text28 thq-body-small">Tracking</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="login-text29 thq-body-small">
              © 2024 Sapna IOT Solutions
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="login-text30 thq-body-small">
              Terms of Service
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="login-text31 thq-body-small">
              Cookies Settings
            </span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="login-text32 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name1"
      ></Footer>
    </div>
  )
}

export default Login
