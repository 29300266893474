import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './title.css'

const Title = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="title-max-width thq-section-max-width">
        <div className="title-content">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="title-text2 thq-heading-1">
                  Leading IoT Solutions Provider
                </h1>
              </Fragment>
            )}
          </h1>
          <div className="title-actions"></div>
        </div>
      </div>
    </div>
  )
}

Title.defaultProps = {
  rootClassName: '',
  heading1: undefined,
}

Title.propTypes = {
  rootClassName: PropTypes.string,
  heading1: PropTypes.element,
}

export default Title
