import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import NotFound from '../components/not-found'
import Footer from '../components/footer'
import './error.css'

const Error = (props) => {
  return (
    <div className="error-container">
      <Helmet>
        <title>error - Sapna IOT</title>
        <meta
          name="description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
        <meta property="og:title" content="error - Sapna IOT " />
        <meta
          property="og:description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
      </Helmet>
      <Header
        link1={
          <Fragment>
            <span className="error-text10 thq-body-small thq-link">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="error-text11 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="error-text12 thq-body-small thq-link">
              Services
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="error-text13 thq-body-small thq-link">
              Tracking
            </span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="error-text14 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="error-text15 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="error-text16">Login</span>
          </Fragment>
        }
        link1Url="/"
        link2Url="/about-us"
        link3Url="/error"
        link4Url="https://www.iopgps.com/"
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name3"
      ></Header>
      <NotFound
        action1={
          <Fragment>
            <span className="error-text17 thq-body-small">
              Please check the URL or navigate back to the homepage.
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="error-text18 thq-body-small">
              The page you are looking for does not exist.
            </span>
          </Fragment>
        }
      ></NotFound>
      <Footer
        link1={
          <Fragment>
            <span className="error-text19 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="error-text20 thq-body-small">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="error-text21 thq-body-small">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="error-text22 thq-body-small">Flowmeter</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="error-text23 thq-body-small">Tracking</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="error-text24 thq-body-small">
              © 2024 Sapna IOT Solutions
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="error-text25 thq-body-small">
              Terms of Service
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="error-text26 thq-body-small">
              Cookies Settings
            </span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="error-text27 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name5"
      ></Footer>
    </div>
  )
}

export default Error
