import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import TitleSubtitle from '../components/title-subtitle'
import Footer from '../components/footer'
import './services.css'

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services - Sapna IOT</title>
        <meta
          name="description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
        <meta property="og:title" content="Services - Sapna IOT " />
        <meta
          property="og:description"
          content="Future Forward: Pioneering the next wave of startup innovation."
        />
      </Helmet>
      <Header
        link1={
          <Fragment>
            <span className="services-text10 thq-body-small thq-link">
              Home
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="services-text11 thq-body-small thq-link">
              About Us
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="services-text12 thq-body-small thq-link">
              Services
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="services-text13 thq-body-small thq-link">
              Tracking
            </span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="services-text14 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        link41={
          <Fragment>
            <span className="services-text15 thq-body-small thq-link">
              Flowmeter
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="services-text16">Login</span>
          </Fragment>
        }
        link1Url="/"
        link2Url="/about-us"
        link3Url="/services"
        link4Url="https://www.iopgps.com/ "
        link4Url1="https://live.dhaara.com/"
        rootClassName="headerroot-class-name7"
      ></Header>
      <TitleSubtitle
        content1={
          <Fragment>
            <span className="services-text17 thq-body-large">
              Our state-of-the-art flow meter system delivers precise, real-time
              data on the quantity of petrol and diesel being dispensed.
              Designed for accuracy and reliability, this technology allows you
              to monitor fuel usage with unmatched precision, ensuring optimal
              resource management.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="services-text18 thq-heading-1">Flowmeter</span>
          </Fragment>
        }
        rootClassName="title-subtitleroot-class-name2"
      ></TitleSubtitle>
      <a
        href="https://live.dhaara.com/"
        target="_blank"
        rel="noreferrer noopener"
        className="services-link1 button"
      >
        Click here
      </a>
      <TitleSubtitle
        content1={
          <Fragment>
            <span className="services-text19 thq-body-large">
              Our vehicle tracking system offers real-time visibility of your
              fleet&apos;s location, presented on an easy-to-navigate
              interactive map. With this powerful tool, you can monitor the
              exact position of each vehicle, ensuring that you stay informed
              and in control of your logistics.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="services-text20 thq-heading-1">
              Vehicle Tracking
            </span>
          </Fragment>
        }
        rootClassName="title-subtitleroot-class-name3"
      ></TitleSubtitle>
      <a
        href="https://www.iopgps.com/ "
        target="_blank"
        rel="noreferrer noopener"
        className="services-link2 button"
      >
        Click here
      </a>
      <Footer
        link1={
          <Fragment>
            <span className="services-text21 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="services-text22 thq-body-small">About Us</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="services-text23 thq-body-small">Services</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="services-text24 thq-body-small">Flowmeter</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="services-text25 thq-body-small">Tracking</span>
          </Fragment>
        }
        content3={
          <Fragment>
            <span className="services-text26 thq-body-small">
              © 2024 Sapna IOT Solutions
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="services-text27 thq-body-small">
              Terms of Service
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="services-text28 thq-body-small">
              Cookies Settings
            </span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="services-text29 thq-body-small">
              Privacy Policy
            </span>
          </Fragment>
        }
        rootClassName="footerroot-class-name7"
      ></Footer>
    </div>
  )
}

export default Services
