import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './not-found.css'

const NotFound = (props) => {
  return (
    <div className="not-found-container thq-section-padding">
      <div className="not-found-max-width">
        <h1 className="not-found-text1 thq-heading-1">404</h1>
        <h2 className="not-found-text2 thq-heading-2">Page not found</h2>
        <span>
          {props.content1 ?? (
            <Fragment>
              <span className="not-found-text5 thq-body-small">
                The page you are looking for does not exist.
              </span>
            </Fragment>
          )}
        </span>
        <button className="thq-button-filled">
          <span>
            {props.action1 ?? (
              <Fragment>
                <span className="not-found-text4 thq-body-small">
                  Please check the URL or navigate back to the homepage.
                </span>
              </Fragment>
            )}
          </span>
        </button>
      </div>
    </div>
  )
}

NotFound.defaultProps = {
  action1: undefined,
  content1: undefined,
}

NotFound.propTypes = {
  action1: PropTypes.element,
  content1: PropTypes.element,
}

export default NotFound
