import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './title-subtitle-img.css'

const TitleSubtitleImg = (props) => {
  return (
    <div className="title-subtitle-img-container1">
      <div className="thq-section-padding">
        <div className="title-subtitle-img-max-width thq-section-max-width">
          <div className="thq-flex-row title-subtitle-img-container2">
            <div className="title-subtitle-img-column">
              <div className="title-subtitle-img-content">
                <h1 className="title-subtitle-img-heading1">
                  {props.heading1 ?? (
                    <Fragment>
                      <h1 className="title-subtitle-img-text2 thq-heading-1">
                        {' '}
                        Our Mission
                      </h1>
                    </Fragment>
                  )}
                </h1>
                <p className="title-subtitle-img-content1">
                  {props.content1 ?? (
                    <Fragment>
                      <p className="title-subtitle-img-text1 thq-body-large">
                        To design close gaps in existing community development
                        programs by using well-established principles from
                        research on effective community development processes
                      </p>
                    </Fragment>
                  )}
                </p>
              </div>
              <div className="title-subtitle-img-actions"></div>
            </div>
            <div className="title-subtitle-img-container3">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="title-subtitle-img-image1 thq-img-ratio-4-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TitleSubtitleImg.defaultProps = {
  content1: undefined,
  heading1: undefined,
  image1Src:
    'https://images.unsplash.com/photo-1512314889357-e157c22f938d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMwfHxpZGVhfGVufDB8fHx8MTcyNDQ5NTk2MHww&ixlib=rb-4.0.3&w=1400',
  image1Alt: 'Image of a team collaborating',
}

TitleSubtitleImg.propTypes = {
  content1: PropTypes.element,
  heading1: PropTypes.element,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default TitleSubtitleImg
