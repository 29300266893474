import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import Profile from './views/profile'
import Login from './views/login'
import Error from './views/error'
import AboutUs from './views/about-us'
import Services from './views/services'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Profile} exact path="/profile" />
        <Route component={Login} exact path="/login" />
        <Route component={Error} exact path="/error" />
        <Route component={AboutUs} exact path="/about-us" />
        <Route component={Services} exact path="/services" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
