import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './feature.css'

const Feature = (props) => {
  return (
    <div
      className={`feature-layout301 thq-section-padding ${props.rootClassName} `}
    >
      <div className="feature-max-width thq-section-max-width">
        <h2>
          {props.sectionTitle ?? (
            <Fragment>
              <h2 className="feature-text2 thq-heading-2">Key Features</h2>
            </Fragment>
          )}
        </h2>
        <div className="feature-row thq-grid-auto-300">
          <div className="feature-feature1">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <div className="feature-content1 thq-flex-column">
              <div className="feature-section-title1 thq-flex-column">
                <h3>
                  {props.feature1Title ?? (
                    <Fragment>
                      <h3 className="feature-text8 thq-heading-3">
                        IoT Solutions
                      </h3>
                    </Fragment>
                  )}
                </h3>
                <span>
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="feature-text4 thq-body-small">
                        Cutting-edge IoT solutions tailored to your specific
                        needs.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="feature-feature2">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <div className="feature-content2 thq-flex-column">
              <div className="feature-section-title2 thq-flex-column">
                <strong>
                  {props.feature2Title ?? (
                    <Fragment>
                      <strong className="feature-text5 thq-heading-3">
                        Flowmeter Technology
                      </strong>
                    </Fragment>
                  )}
                </strong>
                <span>
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="feature-text7 thq-body-small">
                        Innovative flowmeter technology for accurate
                        measurements and monitoring.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="feature-feature3">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="thq-img-ratio-4-3"
            />
            <div className="feature-content3 thq-flex-column">
              <div className="feature-section-title3 thq-flex-column">
                <strong>
                  {props.feature3Title ?? (
                    <Fragment>
                      <strong className="feature-text6 thq-heading-3">
                        Tracking Services
                      </strong>
                    </Fragment>
                  )}
                </strong>
                <span>
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="feature-text3 thq-body-small">
                        Advanced tracking services for real-time location
                        monitoring.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Feature.defaultProps = {
  rootClassName: '',
  sectionTitle: undefined,
  feature3Description: undefined,
  feature2ImageAlt: 'Flowmeter Technology Image',
  feature3ImageAlt: 'Tracking Services Image',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1590856029847-4956f6a5fd92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDc4MDQ0MHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Description: undefined,
  feature1ImageAlt: 'IoT Solutions Image',
  feature2Title: undefined,
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1536944838558-c849be01fdf7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDc4MDQ0MHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3Title: undefined,
  feature2Description: undefined,
  feature1ImageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  feature1Title: undefined,
}

Feature.propTypes = {
  rootClassName: PropTypes.string,
  sectionTitle: PropTypes.element,
  feature3Description: PropTypes.element,
  feature2ImageAlt: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature1Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature2Title: PropTypes.element,
  feature2ImageSrc: PropTypes.string,
  feature3Title: PropTypes.element,
  feature2Description: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  feature1Title: PropTypes.element,
}

export default Feature
