import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './objectives.css'

const Objectives = (props) => {
  return (
    <div className="objectives-layout349 thq-section-padding">
      <div className="objectives-max-width thq-section-max-width">
        <div className="objectives-container1">
          <h2>
            {props.heading ?? (
              <Fragment>
                <h2 className="objectives-text20 thq-heading-2">Objectives</h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.text ?? (
              <Fragment>
                <span className="objectives-text24 thq-body-small">
                  We providing training in different technologies and We make
                  you zero to hero, and get a certificate
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-3">
          <div className="objectives-container3 thq-card">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="objectives-text21 thq-heading-2">1</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text1 ?? (
                <Fragment>
                  <span className="objectives-text19 thq-body-small">
                    {' '}
                    To involve citizens examining their present community
                    problems and choosing to renew it with simple
                    solutions.(Consulting)
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="objectives-container4 thq-card">
            <h2>
              {props.heading2 ?? (
                <Fragment>
                  <h2 className="objectives-text22 thq-heading-2">2</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text2 ?? (
                <Fragment>
                  <span className="objectives-text25 thq-body-small">
                    To achieve diversity and develop new community leaders in
                    technology ( Training)
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="objectives-container5 thq-card">
            <h2>
              {props.heading3 ?? (
                <Fragment>
                  <h2 className="objectives-text23 thq-heading-2">3</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.text3 ?? (
                <Fragment>
                  <span className="objectives-text18 thq-body-small">
                    For developing new research directions in educational and
                    employment skill levels of several communities
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Objectives.defaultProps = {
  text3: undefined,
  text1: undefined,
  heading: undefined,
  heading1: undefined,
  heading2: undefined,
  heading3: undefined,
  text: undefined,
  text2: undefined,
}

Objectives.propTypes = {
  text3: PropTypes.element,
  text1: PropTypes.element,
  heading: PropTypes.element,
  heading1: PropTypes.element,
  heading2: PropTypes.element,
  heading3: PropTypes.element,
  text: PropTypes.element,
  text2: PropTypes.element,
}

export default Objectives
